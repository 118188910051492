import React from 'react';
import styled from 'styled-components';
import { SEO } from 'components';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const PrivacyPolicy = () => {
  return (
    <Wrapper>
      <SEO
        pageSpecificTitle="Privacy Policy"
        pageSpecificDescription="Our Privacy Policy"
        meta={[
          {
            name: 'robots',
            content: 'noindex'
          }
        ]}
      />
      <h1>Agency X Privacy Policy</h1>
      <b>Introduction</b>
      <p>
        Welcome to the Agency X Limited privacy notice. Agency X Limited respects your privacy and
        is committed to protecting your personal data. This privacy notice will inform you as to how
        we look after your personal data when you visit our website (regardless of where you visit
        it from) and tell you about your privacy rights and how the law protects you. This privacy
        notice is provided in a layered format so you can click through to the specific areas set
        out below.
      </p>
      <p>
        <a href="https://campaign-x.com" target="blank">
          Campaign X
        </a>{' '}
        and{' '}
        <a href="https://eventxlive.com" target="blank">
          AX Engage
        </a>
        , which are both services provided by Agency X are also covered by this same privacy policy.
      </p>
      <LinksList>
        <li>
          <AnchorLink to="/privacy-policy#important-info" title="Important Information">
            Important information and who we are
          </AnchorLink>
        </li>
        <li>
          <AnchorLink to="/privacy-policy#data-collected" title="Data We Collect">
            The data we collect about you
          </AnchorLink>
        </li>
        <li>
          <AnchorLink to="/privacy-policy#how-we-collect-data" title="How We Collect Data">
            How is your personal data collected
          </AnchorLink>
        </li>
        <li>
          <AnchorLink to="/privacy-policy#how-we-use-data" title="How We Use Data">
            How we use your personal data
          </AnchorLink>
        </li>
        <li>
          <AnchorLink to="/privacy-policy#disclosures" title="Disclosures">
            Disclosures of your personal data
          </AnchorLink>
        </li>
        <li>
          <AnchorLink to="/privacy-policy#international-transfers" title="International Transfers">
            International transfers
          </AnchorLink>
        </li>
        <li>
          <AnchorLink to="/privacy-policy#data-security" title="Data Security">
            Data security
          </AnchorLink>
        </li>
        <li>
          <AnchorLink to="/privacy-policy#data-retention" title="Data Retention">
            Data retention
          </AnchorLink>
        </li>
        <li>
          <AnchorLink to="/privacy-policy#legal-rights" title="Legal Rights">
            Your legal rights
          </AnchorLink>
        </li>
        <li>
          <AnchorLink to="/privacy-policy#contact-us" title="Contact Us">
            Contact Us
          </AnchorLink>
        </li>
      </LinksList>
      <DetailedList>
        <li>
          <div>
            <h4 id="important-info">Important information and who we are</h4>
            <b>Purpose of this privacy notice</b>
            <p>
              This privacy notice aims to give you information on how Agency X Limited collects and
              processes your personal data through your use of this website, including any data you
              may provide through this website when you purchase a product or service. This website
              is not intended for children and we do not knowingly collect data relating to
              children. It is important that you read this privacy notice together with any other
              privacy notice or fair processing notice we may provide on specific occasions when we
              are collecting or processing personal data about you so that you are fully aware of
              how and why we are using your data. This privacy notice supplements the other notices
              and is not intended to override them.
            </p>
            <b>Controller</b>
            <p>
              Agency X Limited is the controller and responsible for your personal data (referred to
              as Agency X, “we”, “us” or “our” in this privacy notice) as a direct client or
              prospect of Agency X.
            </p>
            <b>Processor</b>
            <p>
              In connection with personal data provided through a clients use of the dotmailer
              platform and other services that we provide, we are a ‘processor’ for the purposes of
              the General Data Protection Regulation. We have appointed a data privacy manager who
              is responsible for overseeing questions in relation to this privacy notice. If you
              have any questions about this privacy notice, including any requests to exercise your
              legal rights, please contact the data privacy manager using the details set out below.
            </p>
            <b>Contact details</b>
            <p>
              Our full details are: Full name of legal entity: Agency X Limited Title of data
              privacy manager: Data Officer Email address: dataofficer@Agency X.ie Postal address:
              12 Hatch Street Lower, Dublin 2 Telephone number: +353 1 670 6122 You have the right to
              make a complaint at any time to the Data Protection Commission (DPC), the Irish
              supervisory authority for data protection issues (www.dataprotection.ie). We would,
              however, appreciate the chance to deal with your concerns before you approach the DPC
              so please contact us in the first instance.
            </p>
            <b>Changes to the privacy notice and your duty to inform us of changes</b>
            <p>
              This version was last updated on 3rd May 2018 It is important that the personal data
              we hold about you is accurate and current. Please keep us informed if your personal
              data changes during your relationship with us.
            </p>
            <b>Third-party links</b>
            <p>
              This website may include links to third -party websites, plug-ins and applications.
              Clicking on those links or enabling those connections may allow third parties to
              collect or share data about you. We do not control these third-party websites and are
              not responsible for their privacy statements. When you leave our website, we encourage
              you to read the privacy notice of every website you visit.
            </p>
          </div>
        </li>
        <li>
          <div>
            <h4 id="data-collected">The data we collect about you</h4>
            <p>
              Personal data, or personal information, means any information about an individual from
              which that person can be identified. It does not include data where the identity has
              been removed (anonymous data). We may collect, use, store and transfer different kinds
              of personal data about you which we have grouped together follows:
            </p>
            <ul>
              <li>
                <b>Identity Data</b> includes first name, last name, username or similar identifier.
              </li>
              <li>
                <b>Contact Data</b> includes billing address, delivery address, email address and
                telephone numbers.
              </li>
              <li>
                <b>Financial Data</b> includes bank account details.
              </li>
              <li>
                <b>Transaction Data</b> includes details about payments to and from you and other
                details of products and services you have purchased from us.
              </li>
              <li>
                <b>Technical Data</b> includes internet protocol (IP) address, your login data,
                browser type and version, time zone setting and location, browser plug-in types and
                versions, operating system and platform and other technology on the devices you use
                to access this website
              </li>
              <li>
                <b>Profile Data</b> includes purchases or orders made by you, feedback and survey
                responses.
              </li>
              <li>
                <b>Usage Data</b> includes information about how you use our website, products and
                services.
              </li>
              <li>
                <b>Marketing and Communications Data</b> includes your preferences in receiving
                marketing from us and our third parties and your communication preferences. We also
                collect, use and share <b>Aggregated Data</b> such as statistical or demographic
                data for any purpose. Aggregated Data may be derived from your personal data but is{' '}
                <b>not</b> considered personal data in law as this data does not directly or
                indirectly reveal your identity. For example, we may aggregate your Usage Data to
                calculate the percentage of users accessing a specific website feature. However, if
                we combine or connect Aggregated Data with your personal data so that it can
                directly or indirectly identify you, we treat the combined data as personal data
                which will be used in accordance with this privacy notice. We do not collect any{' '}
                <b>Special Categories of Personal Data</b> about you (this includes details about
                your race or ethnicity, religious or philosophical beliefs, sex life, sexual
                orientation, political opinions, trade union membership, information about your
                health and genetic and biometric data). Nor do we collect any information about
                criminal convictions and offences. <b>If you fail to provide personal data</b> where
                we need to collect personal data by law, or under the terms of a contract we have
                with you and you fail to provide that data when requested, we may not be able to
                perform the contract we have or are trying to enter into with you (for example, to
                provide you with goods or services). In this case, we may have to cancel a product
                or service you have with us but we will notify you if this is the case at the time.
              </li>
            </ul>
            <br />
          </div>
        </li>
        <li>
          <div>
            <h4 id="how-we-collect-data">How is your personal data collected?</h4>
            <p>We use different methods to collect data from and about you including through:</p>
            <ul>
              <li>
                <b>Direct interactions</b>
                <p>
                  You may give us your Identity, Contact and Financial Data by filling in forms or
                  by corresponding with us by post, phone, email or social media otherwise. This
                  includes personal data you provide when you:
                </p>
                <ul>
                  <li>Apply for our products or services</li>
                  <li>Subscribe to our service or publications</li>
                  <li>Request marketing information to be sent to you</li>
                  <li>Enter a competition, promotion or survey; or</li>
                  <li>Give us some feedback.</li>
                </ul>
              </li>
              <br />
              <li>
                <b>Automated technologies or interactions</b>
                <p>
                  As you interact with our website, we may automatically collect Technical Data
                  about your equipment, browsing actions and patterns. We collect this personal data
                  by using cookies and other similar technologies. [We may also receive Technical
                  Data about you if you visit other websites employing our cookies. Please click
                  here for more information{' '}
                  <a href="https://www.cookiehub.com/about-cookies">about cookies</a>]. We use email
                  marketing providers and who send marketing emails on our behalf they provide
                  information to us as to whether emails have been opened or deleted by you.
                </p>
              </li>
              <li>
                <b>Third parties or publicly available sources</b>
                <p>
                  We may receive personal data about you from various third parties [and public
                  sources] as set out below: Technical Data from the following parties:
                </p>
                <AlphaBeticalList style={{ marginBottom: '1em' }}>
                  <li>analytics providers, such as Google;</li>
                  <li>advertising networks such as Facebook, Google and LinkedIn</li>
                  <li>search information providers such as Google.</li>
                </AlphaBeticalList>
              </li>
              <li>
                <p>
                  <b>Identity and Contact Data</b> from publicly availably sources such as Companies
                  Registration Office.
                </p>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div>
            <h4 id="how-we-use-data">How we use your personal data</h4>
            <p>
              We will only use your personal data when the law allows us to. Most commonly, we will
              use your personal data in the following circumstances:
            </p>
            <ul>
              <li>
                <p>
                  Where we need to perform the contract we are about to enter into or have entered
                  into with you. This means that the processing of your data is necessary for the
                  performance of a contract to which you are a party or to take steps at your
                  request before entering into such a contract.
                </p>
              </li>
              <li>
                <p>
                  Where it is necessary for our legitimate interests (or those of a third party)
                  and/or you have agreed to our processing of your information. Legitimate Interest
                  means the interest of our business in conducting and managing our business to
                  enable us to give you the best service/product and the best and most secure
                  experience. We make sure we consider and balance any potential impact on you (both
                  positive and negative) and your rights before we process your personal data for
                  our legitimate interests. We do not use your personal data for activities where
                  our interests are overridden by the impact on you (unless we have your consent or
                  are otherwise required or permitted to by law). You can obtain further information
                  about how we assess our legitimate interests against any potential impact on you
                  in respect of specific activities by contacting us.
                </p>
              </li>
              <li>
                <p>
                  Where we need to comply with a legal or regulatory obligation. This means
                  processing your personal data where it is necessary for compliance with a legal or
                  regulatory obligation that we are subject to. Generally we do not rely on consent
                  as a legal basis for processing your personal data other than in relation to
                  sending third party direct marketing communications to you via email or text
                  message. You have the right to withdraw consent to marketing at any time by
                  contact us.
                </p>
              </li>
            </ul>
            <b>Purposes for which we will use your personal data</b>
            <p>
              We have set out below, in a table format, a description of all the ways we plan to use
              your personal data, and which of the legal bases we rely on to do so. We have also
              identified what our legitimate interests are where appropriate. Note that we may
              process your personal data for more than one lawful ground depending on the specific
              purpose for which we are using your data. Please contact us if you need details about
              the specific legal ground we are relying on to process your personal data where more
              than one ground has been set out in the table below.
            </p>
            <table>
              <tbody>
                <tr>
                  <th style={{ fontWeight: '700' }}>Purpose/Activity</th>
                  <th style={{ fontWeight: '700' }}>Type of data</th>
                  <th style={{ fontWeight: '700' }}>
                    Lawful basis for processing including basis of legitimate interest
                  </th>
                </tr>
                <tr>
                  <td>To register you as a new customer</td>
                  <td>
                    (a) Identity <br />
                    (b) Contact
                  </td>
                  <td>Performance of a contract with you</td>
                </tr>
                <tr>
                  <td>
                    To process and deliver your order including: <br />
                    (a) Manage payments, fees and charges <br />
                    (b) Collect and recover money owed to us
                  </td>
                  <td>
                    (a) Identity <br />
                    (b) Contact <br />
                    (c) Financial <br />
                    (d) Transaction <br />
                    (e) Marketing and Communications
                  </td>
                  <td>
                    (a) Performance of a contract with you <br />
                    (b) Necessary for our legitimate interests (to recover debts due to us)
                  </td>
                </tr>
                <tr>
                  <th>
                    To manage our relationship with you which will include:
                    <br />
                    (a) Notifying you about changes to our terms or privacy policy
                    <br />
                    (b) Asking you to leave a review or take a survey
                  </th>
                  <th>
                    (a) Identity <br />
                    (b) Contact <br />
                    (c) Profile <br />
                    (d) Marketing and Communications
                  </th>
                  <th>
                    (a) Performance of a contract with you <br />
                    (b) Necessary to comply with a legal obligation
                    <br />
                    (c) To keep our records updated and to study how customers use our
                    products/services
                  </th>
                </tr>
                <tr>
                  <th>
                    To enable you to partake in a prize draw, competition or complete a survey{' '}
                    <br />
                  </th>
                  <th>
                    (a) Identity <br />
                    (b) Contact <br />
                    (c) Profile <br />
                    (d) Usage <br />
                    (e) Marketing and Communications
                  </th>
                  <th>
                    (a) Performance of a contract with you <br />
                    (b) Tto study how customers use our products/services, to develop them and grow
                    our business
                    <br />
                  </th>
                </tr>
                <tr>
                  <th>
                    To administer and protect our business and this website (including
                    troubleshooting, data analysis, testing, system maintenance, support, reporting
                    and hosting of data)
                  </th>
                  <th>
                    (a) Identity
                    <br />
                    (b) Contact
                    <br />
                    (c) Technical
                  </th>
                  <th>
                    (a) Necessary for our legitimate interests (for running our business, provision
                    of administration and IT services, network security, to prevent fraud and in the
                    context of a business reorganisation or group restructuring exercise)
                    <br />
                    (b) Necessary to comply with a legal obligation
                  </th>
                </tr>
                <tr>
                  <th>
                    To deliver relevant website content and advertisements to you and measure or
                    understand the effectiveness of the advertising we serve to you
                  </th>
                  <th>
                    (a) Identity <br />
                    (b) Contact <br />
                    (c) Profile <br />
                    (d) Usage <br />
                    (e) Marketing and Communications <br />
                    (f) Technical
                  </th>
                  <th>
                    To study how customers use our products/services, to develop them, to grow our
                    business and to inform our marketing strategy
                  </th>
                </tr>
                <tr>
                  <th>
                    To use data analytics to improve our website, products/services, marketing,
                    customer relationships and experiences
                  </th>
                  <th>
                    (a) Technical <br />
                    (b) Usage
                  </th>
                  <th>
                    To define types of customers for our products and services, to keep our website
                    updated and relevant, to develop our business and to inform our marketing
                    strategy
                  </th>
                </tr>
                <tr>
                  <th>
                    To make suggestions and recommendations to you about goods or services that may
                    be of interest to you
                  </th>
                  <th>
                    (a) Identity <br />
                    (b) Contact <br />
                    (c) Technical <br />
                    (d) Usage <br />
                    (e) Profile
                  </th>
                  <th>To develop our products/services and grow our business)</th>
                </tr>
              </tbody>
            </table>
            <b>Marketing</b>
            <p>
              We strive to provide you with choices regarding certain personal data uses,
              particularly around marketing and advertising. We have established the following
              personal data control mechanisms:
            </p>
            <ul>
              <li>
                <b>Promotional offers from us</b>
                <p>
                  We may use your Identity, Contact, Technical, Usage and Profile Data to form a
                  view on what we think you may want or need, or what may be of interest to you.
                  This is how we decide which products, services and offers may be relevant for you
                  (we call this marketing). You will receive marketing communications from us if you
                  have purchased goods or services from us and, in each case, you have not opted out
                  of receiving that marketing.
                </p>
              </li>
              <li>
                <b>Third-party marketing</b>
                <p>
                  We will get your express opt-in consent before we share your personal data with
                  any company outside Agency X Limited for marketing purposes.
                </p>
              </li>
              <li>
                <b>Opting out</b>
                <p>
                  You can ask us or third parties to stop sending you marketing messages at any time
                  by contacting us at any time. Where you opt out of receiving these marketing
                  messages, this will not apply to personal data provided to us as a result of a
                  product/service purchase, warranty registration, product/service experience or
                  other transactions.
                </p>
              </li>
              <li>
                <b>Cookies</b>
                <p>
                  You can set your browser to refuse all or some browser cookies, or to alert you
                  when websites set or access cookies. If you disable or refuse cookies, please note
                  that some parts of this website may become inaccessible or not function properly.
                  For more information about the cookies we use, please see our cookie policy.
                  Change of purpose We will only use your personal data for the purposes for which
                  we collected it, unless we reasonably consider that we need to use it for another
                  reason and that reason is compatible with the original purpose. If you wish to get
                  an explanation as to how the processing for the new purpose is compatible with the
                  original purpose, please contact us. If we need to use your personal data for an
                  unrelated purpose, we will notify you and we will explain the legal basis which
                  allows us to do so. Please note that we may process your personal data without
                  your knowledge or consent, in compliance with the above rules, where this is
                  required or permitted by law.
                </p>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div>
            <h4 id="disclosures">Disclosures of your personal data</h4>
            <p>
              We may have to share your personal data with the parties set out below for the
              purposes set out in the table in paragraph 4 above.
            </p>
            <ul>
              <li>Internal Third Parties</li>
              <li>
                External Third Parties:
                <ol style={{ paddingLeft: '2em' }}>
                  <li>
                    Service providers e.g. Google, Microsoft and others who provide IT and system
                    administration services.
                  </li>
                  <li>
                    Professional advisers including lawyers, bankers, auditors and insurers based in
                    Ireland who provide consultancy, banking, legal, insurance and accounting
                    services.
                  </li>
                  <li>
                    Revenue, regulators and other authorities based in Ireland who require reporting
                    of processing activities in certain circumstances.
                  </li>
                  <li>dotdigital for email marketing services</li>
                  <li>
                    Third parties to whom we may choose to sell, transfer, or merge parts of our
                    business or our assets. Alternatively, we may seek to acquire other businesses
                    or merge with them. If a change happens to our business, then the new owners may
                    use your personal data in the same way as set out in this privacy notice. We
                    require all third parties to respect the security of your personal data and to
                    treat it in accordance with the law. We do not allow our third-party service
                    providers to use your personal data for their own purposes and only permit them
                    to process your personal data for specified purposes and in accordance with our
                    instructions.
                  </li>
                </ol>
              </li>
            </ul>
          </div>
        </li>
        <br />
        <li>
          <div>
            <h4 id="international-transfers">International transfers</h4>
            <p>
              Your personal information may be stored and processed in any country where we have
              facilities or service providers, and by using our service or by providing consent to
              us (where required by law), you agree to the transfer of information to countries
              outside of your country of residence, including to the United States, which may
              provide for different data protection rules than in your country. Please note that all
              dotmailer entities operate under Standard Contractual Clauses and applicable measures
              of the GDPR providing protections and safeguards for cross-border transfers. We take
              all steps reasonably necessary to ensure that your data is treated securely and in
              accordance with this privacy policy.
            </p>
          </div>
        </li>
        <li>
          <div>
            <h4 id="data-security">Data security</h4>
            <p>
              We have put in place appropriate security measures to prevent your personal data from
              being accidentally lost, used or accessed in an unauthorised way, altered or
              disclosed. In addition, we limit access to your personal data to those employees,
              agents, contractors and other third parties who have a business need to know. They
              will only process your personal data on our instructions and they are subject to a
              duty of confidentiality. We have put in place procedures to deal with any suspected
              personal data breach and will notify you and any applicable regulator of a breach
              where we are legally required to do so.
            </p>
          </div>
        </li>
        <li>
          <div>
            <h4 id="data-retention">Data retention</h4>
            <b>How long will you use my personal data for?</b>
            <p>
              We will only retain your personal data for as long as necessary to fulfil the purposes
              we collected it for, including for the purposes of satisfying any legal, accounting,
              or reporting requirements. To determine the appropriate retention period for personal
              data, we consider the amount, nature, and sensitivity of the personal data, the
              potential risk of harm from unauthorised use or disclosure of your personal data, the
              purposes for which we process your personal data and whether we can achieve those
              purposes through other means, and the applicable legal requirements. Details of
              retention periods for different aspects of your personal data are available in our
              retention policy which you can request from us by contacting us . In some
              circumstances you can ask us to delete your data: see [Request erasure] below for
              further information. In some circumstances we may anonymise your personal data (so
              that it can no longer be associated with you) for research or statistical purposes in
              which case we may use this information indefinitely without further notice to you.
            </p>
          </div>
        </li>
        <li>
          <div>
            <h4 id="legal-rights">Your legal rights</h4>
            <p>
              Under certain circumstances, you have rights under data protection laws in relation to
              your personal data. You have the right to:
            </p>
            <ul>
              <li>
                <p>
                  Request access to your personal data (commonly known as a “data access request”).
                  This enables you to receive a copy of the personal data we hold about you and to
                  check that we are lawfully processing it.
                </p>
              </li>
              <li>
                <p>
                  Request correction of the personal data that we hold about you. This enables you
                  to have any incomplete or inaccurate data we hold about you corrected, though we
                  may need to verify the accuracy of the new data you provide to us.
                </p>
              </li>
              <li>
                <p>
                  Request erasure of your personal data. This enables you to ask us to delete or
                  remove personal data where there is no good reason for us continuing to process
                  it. You also have the right to ask us to delete or remove your personal data where
                  you have successfully exercised your right to object to processing (see below),
                  where we may have processed your information unlawfully or where we are required
                  to erase your personal data to comply with local law. Note, however, that we may
                  not always be able to comply with your request of erasure for specific legal
                  reasons which will be notified to you, if applicable, at the time of your request.{' '}
                </p>
              </li>
              <li>
                <p>
                  Object to processing of your personal data where we are relying on a legitimate
                  interest (or those of a third party) and there is something about your particular
                  situation which makes you want to object to processing on this ground as you feel
                  it impacts on your fundamental rights and freedoms. You also have the right to
                  object where we are processing your personal data for direct marketing purposes.
                  In some cases, we may demonstrate that we have compelling legitimate grounds to
                  process your information which override your rights and freedoms.
                </p>
              </li>
              <li>
                <p>
                  Request restriction of processing of your personal data. This enables you to ask
                  us to suspend the processing of your personal data in the following scenarios:
                </p>
                <AlphaBeticalList>
                  <li>if you want us to establish the data’s accuracy;</li>
                  <li>where our use of the data is unlawful but you do not want us to erase it;</li>
                  <li>
                    where you need us to hold the data even if we no longer require it as you need
                    it to establish, exercise or defend legal claims; or
                  </li>
                  <li>
                    you have objected to our use of your data but we need to verify whether we have
                    overriding legitimate grounds to use it.
                  </li>
                </AlphaBeticalList>
                <br />
              </li>
              <li>
                Request the transfer of your personal data to you or to a third party. We will
                provide to you, or a third party you have chosen, your personal data in a
                structured, commonly used, machine-readable format. Note that this right only
                applies to automated information which you initially provided consent for us to use
                or where we used the information to perform a contract with you.
              </li>
              <li>
                Withdraw consent at any time where we are relying on consent to process your
                personal data. However, this will not affect the lawfulness of any processing
                carried out before you withdraw your consent. If you withdraw your consent, we may
                not be able to provide certain products or services to you. We will advise you if
                this is the case at the time you withdraw your consent.
              </li>
              <li>
                Exercise your rights If you wish to exercise any of the rights set out above, please
                contact us at dataofficer@Agency X.ie OR contact Data Officer, Agency X Limited, 12 Hatch Street Lower, Dublin 2, D02 R682, Ireland, +353 1 670 6122.{' '}
              </li>
              <li>
                No fee usually required You will not have to pay a fee to access your personal data
                (or to exercise any of the other rights). However, we may charge a reasonable fee if
                your request is clearly unfounded, repetitive or excessive. Alternatively, we may
                refuse to comply with your request in these circumstances.{' '}
              </li>
              <li>
                What we may need from you We may need to request specific information from you to
                help us confirm your identity and ensure your right to access your personal data (or
                to exercise any of your other rights). This is a security measure to ensure that
                personal data is not disclosed to any person who has no right to receive it. We may
                also contact you to ask you for further information in relation to your request to
                speed up our response.{' '}
              </li>
            </ul>
          </div>
        </li>
        <br />
        <br />
        <div>
          <h4 id="contact-us">Contact Us</h4>
          <p>
            If you wish to contact us regarding the use of your personal information or with a
            complaint:
            <br />
            Email: dataofficer@agencyx.ie
            <br />
            Post: Data Officer, Agency X Limited, 12 Hatch Street Lower, Dublin, D02 R682, Ireland.
            <br />
            Phone: +353 1 670 6122
          </p>
        </div>
      </DetailedList>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: var(--ax-beige);
  position: relative;
  padding: 2em;
  margin: 90px auto 0;
  max-width: 62.5rem;

  a {
    color: #1d1d1b;
    outline: none;
  }

  p {
    margin-bottom: 1em;
  }

  h1 {
    text-align: center;
    margin: 0 auto 1em;
  }

  li {
    font-size: 0.725rem;
    margin-left: 1em;
  }

  ol ul {
    margin-left: 2em;
    list-style-type: disc;
  }

  table {
    margin: 1.25em auto;
  }

  th {
    font-weight: 400;
  }

  td,
  th {
    border: 1px solid black;
    padding: 20px;
  }

  @media screen and (min-width: 48rem) {
    li {
      font-size: 0.8rem;
    }
  }

  @media screen and (min-width: 62.5rem) {
    li {
      font-size: 0.9rem;
    }
  }
`;

const AlphaBeticalList = styled.ol`
  list-style-type: lower-alpha;
  padding-left: 2em;
`;

const LinksList = styled.ol`
  margin-bottom: 1.5em;
`;

const DetailedList = styled.ol`
  padding-left: 0;

  div {
    margin-left: 0.75em;
  }

  h4 {
    margin-bottom: 0.5em;
  }
`;

export default PrivacyPolicy;
